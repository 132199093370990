import React from 'react';
import { Container, Section } from '../common/Elements';

interface Props {}

const PreviewBanner: React.FC<Props> = props => {
  return (
    <Section padding={1} position="sticky" backgroundColor="blue0" color="white">
      <Container fontWeight={6}>Preview Mode</Container>
    </Section>
  );
};

export default PreviewBanner;
